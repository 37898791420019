.searchForm {
  display: flex;
  padding: 16px;
  background-color: #fff;
  border-radius: 2px;
  margin-bottom: 16px;
  width: 100%;

  :global(.ant-form-item) {
    //display: flex;
    margin-bottom: 0;
  }

  :global(.ant-form-item-row) {
    //flex: 1;
  }

  :global(.ant-form-item-control) {
    //overflow: hidden;
  }

  :global(.ant-form-item-label) {
    //min-width: 6em;
    //text-align: right;
    //box-sizing: border-box;
  }

  .form {
    width: 100%;
  }

  .btns {
    flex-shrink: 0;

    .submitBtn {
      margin: 0 8px;
      //background: #023590;
      //border-color: #023590;
      //color: #fff;

      &:hover {
        //background: rgba(245, 199, 32, 0.8);
        //border-color: rgba(245, 199, 32, 0.8);
      }
    }

    .resetBtn {
      margin: 0 8px;

      &:hover {
        //color: #023590;
        //border-color: #023590;
      }
    }

    a {
      //color: #023590;
    }
  }
}


.statisticsCard {
  position: relative;
  text-align: center;
}

.statisticsCard span {
  color: rgba(0, 0, 0, .45);
  display: inline-block;
  line-height: 22px;
  margin-bottom: 4px;
}

.statisticsCard p {
  font-size: 16px;
  line-height: 32px;
  margin: 0;
}

.statisticsCard em {
  background-color: #e8e8e8;
  position: absolute;
  height: 56px;
  width: 1px;
  top: 0;
  right: 0;
}

.breadcrumb {
  margin: -16px -16px 16px -16px;
  padding: 16px;
  background: #fff;
  border-bottom: 1px solid #e8e8e8;
  font-weight: bold;

  .title {
    margin-bottom: 9px;
    font-size: 20px;
    line-height: 50px;
  }
}

.tableCard {
  border: 1px solid #f0f0f0;
  background: #fff;
  border-radius: 2px;

  :global(.ant-pagination) {
    padding: 0 16px;
  }

  .toolbar {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 64px;
    padding: 0 16px 0 16px;

    .title {
      flex: 1;
      font-weight: 500;
      font-size: 16px;
      opacity: 0.8;
    }

    .option {
      display: flex;
      align-items: center;
      justify-content: flex-end;

      .icons {
        :global(.anticon) {
          font-size: 16px;
          margin-left: 8px;
        }
      }
    }
  }
}

.tableFields {
  :global(.ant-popover-title) {
    padding: 10px 16px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  :global(.ant-popover-inner-content) {
    padding: 8px 0;

    :global(.ant-checkbox-group) {
      display: block;
    }


    :global(.ant-checkbox-wrapper) {
      display: flex;
      height: 30px;
      line-height: 30px;
      margin: 0;
      padding: 0 16px;
    }

    :global(.ant-checkbox-wrapper):hover {
      background: rgba(0, 0, 0, 0.025)
    }
  }
}

.tableFooter {
  position: fixed;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  height: 48px;
  width: calc(100% - 208px);
  padding: 0 16px;
  background: #fff;
  box-shadow: 0 -1px 4px rgba(0, 21, 41, 0.08);

  .left {
    flex: 1;

    span {
      color: #023590;
      font-weight: 600;
    }
  }
}


.uploadSort {
  height: 100%;
  position: relative;
  z-index: 999;

  .left {
    display: none;
    position: absolute;
    left: 31px;
    bottom: 30%;
    color: #FFFFFF;
    z-index: 999;
    cursor: pointer;
  }

  .right {
    display: none;
    position: absolute;
    right: 32px;
    bottom: 30%;
    color: #FFFFFF;
    cursor: pointer;
    z-index: 999;
  }

  &:hover {
    :global(.ant-upload-list-item-info::before) {
      opacity: 1 !important;
    }

    :global(.ant-upload-list-item-actions) {
      opacity: 1 !important;
      top: 40%;
    }

    :global(.ant-upload-list-item-card-actions-btn) {
      opacity: 1 !important;
    }

    .left {
      display: block;
    }

    .right {
      display: block;
    }
  }
}

:global(.ant-upload-list-item-actions) {
  a{
    font-size: 14px;
  }
}

@primary-color: #1890FF;@font-size-base: 12px;@layout-sider-background: #FAFAFA;@layout-trigger-background: #FAFAFA;@table-header-bg: #FAFAFA;@table-border-color: #E8E8E8;@table-padding-vertical: 10px;@table-padding-horizontal: 12px;
.container {
  width: auto;
  position: relative;
  z-index: 1;
  overflow: hidden;
}
.data{
  width: 100%;
  height: 80px;
  margin-bottom: 30px;
  padding: 0 20px;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  align-content: center;

  .list{
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;

    .h1{
      font-size: 32px;
      line-height: 48px;
      font-weight: bold;
      color: #000;
      //margin-bottom: 20px;
    }
    .h2{
      font-size: 12px;
      line-height: 16px;
      color: #000;
      text-align: center;
    }
  }
  .line{
    width: 1px;
    height: 48px;
    background: #ddd;
    margin: auto auto;
  }
}
.mapBox{
  width: 100%;
  min-height: 500px;
  background-color: #f4f4f4;
}
@primary-color: #1890FF;@font-size-base: 12px;@layout-sider-background: #FAFAFA;@layout-trigger-background: #FAFAFA;@table-header-bg: #FAFAFA;@table-border-color: #E8E8E8;@table-padding-vertical: 10px;@table-padding-horizontal: 12px;
.container {
  width: auto;
  background: #f0f2f5 url('./bg.png') center no-repeat;
  background-size: cover;
  height: 100vh;
  min-height: 680px;
  position: relative;
  z-index: 1;
  overflow: hidden;
}

/* .logo {
  width: 150px;
  height: 100%;
  position: absolute;
  top: 40px;
  left: 40px;
  z-index: 2;
  background: url('./logo.png') no-repeat top;
} */

.container h1 {
  margin-bottom: 50px;
  line-height: 140%;
  font-size: 30px;
  color: #000000;
  background-size: auto;
  background-position: 0px 39px;
  text-align: center;
  letter-spacing: 3px;
  white-space: nowrap;
}

.container h2 {
  margin-bottom: 12px;
  font-size: 16px;
  color: #000;
  text-align: center;
  letter-spacing: 2px;
}

.formCard {
  width: 26%;
  min-width: 440px;
  height: 502px;
  background-color: #fff;
  position: absolute;
  top: 200px;
  right: 200px;
  z-index: 3;
  border-radius: 10px;
  padding: 3% 3.5% 50px;
  box-sizing: border-box;

  .inputTitle {
    height: 28px;
    line-height: 28px;
    color: #333;
    margin: 0;
    padding: 0;
  }
}

.formContainer {
  width: 328px;
  margin: 0 auto;
}

input::selection {
  color: #ffffff;
  background: #000000;
}

input::-moz-selection{
  color: #ffffff;
  background: #000000;
}

.loginBtn {
  width: 100px;
  height: 40px;
  color: #000000;
  border-radius: 5px;
  text-align: center;
}

.footer {
  //position: absolute;
  //bottom: 30px;
  color: rgba(0, 0, 0, .30);
  text-align: center;
}

@primary-color: #1890FF;@font-size-base: 12px;@layout-sider-background: #FAFAFA;@layout-trigger-background: #FAFAFA;@table-header-bg: #FAFAFA;@table-border-color: #E8E8E8;@table-padding-vertical: 10px;@table-padding-horizontal: 12px;
.pic {
    margin-top: 5px;
    margin-left: 12px;
    display: flex;
    flex-flow: row wrap;

    :global(.ant-image) {
        width: 140px;
    height: 130px;
    display: flex !important;
    justify-content: center;
    align-items: center;
      }

      :global(.ant-image-img) {
        width: 100%;
    height: 100%;
    object-fit: cover;
      }
}
@primary-color: #1890FF;@font-size-base: 12px;@layout-sider-background: #FAFAFA;@layout-trigger-background: #FAFAFA;@table-header-bg: #FAFAFA;@table-border-color: #E8E8E8;@table-padding-vertical: 10px;@table-padding-horizontal: 12px;
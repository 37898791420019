.header {
  display: flex;
  flex-direction: row;
  padding: 0 12px 0 0;;
  height: 48px;
  line-height: 48px;
  background: #fff;
  box-shadow: 0 1px 4px rgba(0, 21, 41, 0.08);
  z-index: 2;

  .left {
    flex: 1;

    .trigger {
      font-size: 20px;
      line-height: 48px;
      cursor: pointer;
      transition: all 0.3s, padding 0s;
      padding: 0 16px;
      float: left;
    }

    .trigger:hover {
      background: rgba(0, 0, 0, 0.025);
    }
  }

  .right {
    .action {
      cursor: pointer;
      padding: 0 12px;
      display: inline-block;
      transition: all 0.3s;
      height: 100%;
    }

    .action:hover {
      background: rgba(0, 0, 0, 0.025);
    }
  }
}

:global(.ant-btn-primary){
  color: #000 !important;
  background: #FFF200 !important;
  border: #FFF200 !important;
  text-shadow: none;
}


.content {
  overflow-y: overlay;

  .body {
    padding: 16px 16px 60px 16px;
    min-height: 100%;
    margin-bottom: -60px;
  }
}

.sider {
  height: 100%;
  width: 208px;
  min-height: 100vh;
  box-shadow: 2px 0 8px 0 rgba(29, 35, 41, 0.05);
  overflow: hidden;

  .menus {
    overflow: auto;

  }

  .logo {
    height: 64px;
    line-height: 64px;
    padding-left: 35px;
    background-color: #000000;
    overflow: hidden;
  }

  .logo h1 {
    display: inline-block;
    vertical-align: middle;
    color: #ffffff;
    font-family: "Helvetica Neue", Arial, Helvetica, sans-serif;
    font-size: 30px;
    margin: 0 0 0 12px;
    font-weight: 600;
  }

  .menus {
    overflow: auto;

    :global{
      .ant-menu-title-content a{
        color: #000 !important;
        transition: color 0s;
      }

      .ant-menu-item-icon{
        color: #000 !important;
      }
    }

    :global(.ant-menu-submenu:hover > .ant-menu-submenu-title > .ant-menu-submenu-arrow), // 右侧上下箭头
    :global(.ant-menu-item:hover),
    :global(.ant-menu-submenu-selected),
    :global(.ant-menu-light .ant-menu-item:hover),
    :global(.ant-menu-light .ant-menu-item-active),
    :global(.ant-menu-light .ant-menu:not(.ant-menu-inline) .ant-menu-submenu-open),
    :global(.ant-menu-light .ant-menu-submenu-active),
    :global(.ant-menu-light .ant-menu-submenu-title:hover) {
      color: #FFF200;
    }

    :global(.ant-menu-item-selected),
    :global(.ant-menu-item-selected:hover) {
      color: #000 !important;
      font-weight: bold;
    }

    :global(.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected) {
      background: #FFF200;
    }

    :global(.ant-menu-item:active),
    :global(.ant-menu-submenu-title:active) {
      background: rgba(2, 53, 144, 0.15);
    }

    :global(.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected:after) {
      display: none;
    }

    :global(.ant-menu-item){
      //margin-top: 0;
    }
  }

  .logo img {
    height: 50px;
  }
}

.footer {
  display: flex;
  height: 60px;
  justify-content: center;
  align-items: center;
  color: rgba(0, 0, 0, .45);
}

.router {
  display: flex;
  height: 80%;
  align-items: center;

  .imgBlock {
    flex: 0 0 62.5%;
    width: 62.5%;
    zoom: 1;
    padding-right: 88px;

    .img {
      float: right;
      height: 360px;
      width: 100%;
      max-width: 430px;
      background-size: contain;
      background: url('./404.svg') no-repeat 50% 50%;
    }
  }

  .title {
    color: #434e59;
    font-size: 72px;
    font-weight: 600;
    line-height: 72px;
    margin-bottom: 16px;
  }

  .desc {
    color: rgba(0, 0, 0, .45);
    font-size: 20px;
    line-height: 28px;
    margin-bottom: 16px;
  }
}

@primary-color: #1890FF;@font-size-base: 12px;@layout-sider-background: #FAFAFA;@layout-trigger-background: #FAFAFA;@table-header-bg: #FAFAFA;@table-border-color: #E8E8E8;@table-padding-vertical: 10px;@table-padding-horizontal: 12px;

.editorContent {
  border: 1px solid #d9d9d9;
  border-radius: 2px;

  .toolBar {
    border-bottom: 1px solid #d9d9d9;
  }

  .editor {
    height: 300px;
    overflow-y: hidden;
  }

  :global(video) {
    display: block;
    width: 100%;
  }
}

:global(.w-e-full-screen-container) {
  z-index: 10;
}

@primary-color: #1890FF;@font-size-base: 12px;@layout-sider-background: #FAFAFA;@layout-trigger-background: #FAFAFA;@table-header-bg: #FAFAFA;@table-border-color: #E8E8E8;@table-padding-vertical: 10px;@table-padding-horizontal: 12px;
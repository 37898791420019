
.commonForm{
  :global(.ant-form-item-label){
    max-width: 180px;
    min-width: 120px;
  }
  :global(.ant-form-item-control){
    max-width: 400px;
  }
}

@primary-color: #1890FF;@font-size-base: 12px;@layout-sider-background: #FAFAFA;@layout-trigger-background: #FAFAFA;@table-header-bg: #FAFAFA;@table-border-color: #E8E8E8;@table-padding-vertical: 10px;@table-padding-horizontal: 12px;
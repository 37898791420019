
.box{
  width: 100%;
  min-height: 400px;
  height: calc(100vh - 420px);
  background: #f6f6f6;
  overflow-y: auto;

  .group{
    width: 100%;
    display: block;
  }
}

.fixTable,
.table {
  border: 1px solid #d9d9d9;

  th,
  td:first-child {
    padding: 8px;
  }

  th {
    background: #e9e9e9;
  }

  .td1 {
    width: 120px;
  }

  .td2 {
    width: 144px;
  }

  :global(.ant-checkbox) {
    top: 0.25em;
  }
}

.fixTable {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  z-index: 2;
  width: 100%;

  .td2 {
    width: 144.5px;
  }
}

.posTd {
  position: relative;
  overflow: hidden;
}

.innerTable {
  width: calc(100% + 4px);
  border: 1px solid #d9d9d9;
  margin: -2px;

  tr {
    position: relative;

    &:after {
      //content: '';
      position: absolute;
      left: -10px;
      right: -10px;
      bottom: 0;
      height: 1px;
      background: #d9d9d9;
    }

    &:last-child:after {
      display: none;
    }
  }

  td {
    position: relative;
    padding: 8px;
    vertical-align: top;

    &:after {
      //content: '';
      position: absolute;
      top: -10px;
      bottom: -10px;
      left: 0;
      width: 1px;
      background: #d9d9d9;
    }

    &:first-child:after {
      display: none;
    }

  }
}
@primary-color: #1890FF;@font-size-base: 12px;@layout-sider-background: #FAFAFA;@layout-trigger-background: #FAFAFA;@table-header-bg: #FAFAFA;@table-border-color: #E8E8E8;@table-padding-vertical: 10px;@table-padding-horizontal: 12px;
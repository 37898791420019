
.tabs {
  :global(.ant-tabs-nav){
    margin-bottom: 0;
  }
  :global(.ant-tabs-nav:before){
    display: none;
  }
  padding-left: 25px;
  margin-bottom: -17px;
}
@primary-color: #1890FF;@font-size-base: 12px;@layout-sider-background: #FAFAFA;@layout-trigger-background: #FAFAFA;@table-header-bg: #FAFAFA;@table-border-color: #E8E8E8;@table-padding-vertical: 10px;@table-padding-horizontal: 12px;